import React, {useState, useEffect, useRef} from 'react';
import {
  Button,
  IconButton,
  Drawer,
  makeStyles,
  Fade,
  useMediaQuery,
  Hidden
} from '@material-ui/core';
import {useAppState} from '../../state';
import {ReactComponent as ArrowLeft} from '../../assets/icons/arrow-left.svg';
import {ReactComponent as ArrowRightWhite} from '../../assets/icons/arrow-right-white.svg';
import {ReactComponent as IconActionHome} from '../../assets/icons/icon-action-home.svg';
import {ReactComponent as IconExpand} from '../../assets/icons/icon-navigation-expand-less.svg';
import {ReactComponent as IconExpandDown} from '../../assets/icons/icon-navigation-expand-more.svg';
import SidebarSlide from './SidebarSlide';
import SidebarClose from './SidebarClose';
import Header, {HEADER_HEIGHT} from '../Header/Header';
import slide1Image from '../../assets/images/slide1.jpg';
import slide2Image from '../../assets/images/slide2.jpg';
import slide3Image from '../../assets/images/slide3.jpg';
import slide4Image from '../../assets/images/slide4.jpg';
import slide5Image from '../../assets/images/slide5.jpg';
import slide6Image from '../../assets/images/slide6.jpg';
import slide7Image from '../../assets/images/slide7.jpg';
import slide8Image from '../../assets/images/slide8.jpg';
import slide9Image from '../../assets/images/slide9.jpg';
import slide10Image from '../../assets/images/slide10.png';

export const SIDEBAR_WIDTH = {
  xs: '400px',
  xsNr: 400,
  lg: '460px',
  lgNr: 460
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    zIndex: 1,
    height: 0,
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      '&, & $drawerPaper': {
        width: SIDEBAR_WIDTH.xs,
        inset: '0 0 0 auto !important'
      }
    },
    [theme.breakpoints.up('lg')]: {
      '&, & $drawerPaper': {
        width: SIDEBAR_WIDTH.lg
      }
    }
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[50],
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      '&:not($drawerPaperExpanded)': {
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1)
      }
    },
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  drawerPaperExpanded: {
    [theme.breakpoints.down('sm')]: {
      top: 0
    }
  },
  header: {
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      position: 'absolute'
    }
  },
  footer: {
    display: 'block',
    position: 'relative',
    width: '100%',
    minHeight: theme.spacing(8.5),
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(10.5)
    }
  },
  footerItem: {
    margin: theme.spacing(2, 0),
    position: 'absolute',
    bottom: 0,
    '&[data-position="left"]': {
      left: theme.spacing(2)
    },
    '&[data-position="top-left"]': {
      left: theme.spacing(2),
      bottom: 'auto',
      top: 0,
      zIndex: 1
    },
    '&[data-position="right"]': {
      right: theme.spacing(2)
    },
    '&[data-position="center"]': {
      left: '50%',
      transform: 'translateX(-50%)',
      margin: theme.spacing(2, 0)
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3, 0),
      '&[data-position="left"]': {
        left: theme.spacing(3)
      },
      '&[data-position="top-left"]': {
        left: theme.spacing(3)
      },
      '&[data-position="right"]': {
        right: theme.spacing(3)
      },
      '&[data-position="center"]': {
        margin: theme.spacing(3, 0)
      }
    }
  },
  dots: {
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.spacing(4.5)
  },
  dot: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    }),
    '&[data-active="true"]': {
      backgroundColor: theme.palette.primary.main
    }
  },
  slides: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    minHeight: theme.spacing(10)
  },
  headerPrimary: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    width: '100%',
    zIndex: 2,
    display: 'none',
    bottom: `calc(100% - ${HEADER_HEIGHT}px)`,
    height: 0,
    backgroundColor: theme.palette.grey[50],
    overflow: 'hidden'
  },
  headerPrimaryShown: {
    display: 'block'
  },
  headerPrimaryClose: {
    position: 'absolute',
    margin: theme.spacing(2.25, 2),
    bottom: 0,
    left: 0,
    '& svg path': {
      fill: theme.palette.primary.main
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3)
    }
  },
  headerPrimaryItem: {
    top: 'auto',
    bottom: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      bottom: 0
    }
  },
  iconButtonContained: {
    '&, &:hover, &:focus, &:active': {
      backgroundColor: theme.palette.primary.main
    }
  },
  footerClose: {
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      position: 'absolute'
    }
  },
  swipeUpRoot: {
    position: 'absolute',
    top: theme.spacing(2.5),
    right: theme.spacing(2.5)
  }
}));

const Sidebar = () => {
  const classes = useStyles();
  const {next, prev, reset, currentSlide, slidesNumber} = useAppState();
  const [headerPrimaryHeight, setHeaderPrimaryHeight] = useState(0);
  const [mobileExpanded, setMobileExpanded] = useState(false);
  const currentCardRef = useRef();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const slideShrinked = !mobileExpanded && !isDesktop;

  useEffect(() => {
    if (currentCardRef?.current) {
      const item = currentCardRef.current;
      const itemContent = item.querySelector('[data-content="true"]');

      const scrollListener = () => {
        const contentTop = itemContent.getBoundingClientRect().top;
        setHeaderPrimaryHeight(
          contentTop > HEADER_HEIGHT
            ? 0
            : contentTop > 0
            ? HEADER_HEIGHT - contentTop
            : HEADER_HEIGHT
        );
      };
      item.addEventListener('scroll', scrollListener);
      scrollListener();
      return () => {
        item?.removeEventListener('scroll', scrollListener);
      };
    }
  }, [currentCardRef.current, setHeaderPrimaryHeight]);

  useEffect(() => {
    if (!currentSlide || isDesktop) {
      setMobileExpanded(false);
    }
  }, [currentSlide, setMobileExpanded, isDesktop]);

  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor={isDesktop ? 'right' : 'bottom'}
      open={currentSlide > 0}
      classes={{
        paper: [classes.drawerPaper, mobileExpanded ? classes.drawerPaperExpanded : ''].join(' ')
      }}
      hideBackdrop={true}
    >
      <Header
        showDelay={500}
        hideDelay={0}
        hidden={currentSlide === 0}
        className={classes.header}
      />
      <div
        className={[
          classes.headerPrimary,
          headerPrimaryHeight > 0 ? classes.headerPrimaryShown : ''
        ].join(' ')}
        style={{height: headerPrimaryHeight}}
      >
        {mobileExpanded ? (
          <IconButton
            className={classes.headerPrimaryClose}
            onClick={() => {
              setMobileExpanded(false);
            }}
          >
            <IconExpandDown />
          </IconButton>
        ) : (
          <SidebarClose className={classes.headerPrimaryClose} primary={true} />
        )}
        <Header primary={true} className={classes.headerPrimaryItem} />
      </div>

      <div className={[classes.footerItem, classes.footerClose].join(' ')} data-position="top-left">
        {mobileExpanded ? (
          <IconButton
            onClick={() => {
              setMobileExpanded(false);
            }}
          >
            <IconExpandDown />
          </IconButton>
        ) : (
          <SidebarClose />
        )}
      </div>

      <div className={classes.slides}>
        <SidebarSlide
          slide={1}
          shrinked={slideShrinked}
          {...(currentSlide === 1 && {ref: currentCardRef})}
          title="Activity of takeaway deliveries in London"
          subtitle="Everyday thousands of food deliveries are fulfilled in London, 
          spending on average £781 per year each. This is only set to rise. 
          Since 2019, Brits have increased their spending on takeaways by 42%. 
          72% of people expect to spend the same or more in the future."
          text="Understanding the spatial nature of the challenges and opportunities 
          facing food delivery services is crucial. Poor delivery times could be 
          affected by factors such as busy restaurants, congested streets or low 
          availability of delivery drivers, all of which are inherently spatial."
          image={slide1Image}
          imageAttribution={`Photo by <a href="https://unsplash.com/@kylebushnell?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Kyle Bushnell</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>`}
        />
        <SidebarSlide
          slide={2}
          shrinked={slideShrinked}
          {...(currentSlide === 2 && {ref: currentCardRef})}
          title="The spatial side of takeaways"
          subtitle="High performing delivery businesses understand the relationships 
          between where people live (or sometimes work or socialize) and where they're 
          ordering food from. Understanding spatial patterns of demographics is key to this."
          text="Businesses are not just looking at the situation now, but constantly seeking out new markets. Identifying ideal market conditions in new cities is key to covering a greater percentage of the population."
          image={slide2Image}
          imageAttribution={`Photo by <a href="https://unsplash.com/@rowanfreeman?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Rowan Freeman</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>`}
        />
        <SidebarSlide
          slide={3}
          shrinked={slideShrinked}
          {...(currentSlide === 3 && {ref: currentCardRef})}
          title="The geography of restaurants"
          subtitle="In addition to demographics, the other key consideration is 
          restaurants. According to OpenStreetMap data, there around around 13,000 
          catering establishments in the Greater London area - that's about 1 restaurant 
          per 100 residents!"
          text="Key to successful delivery operations is understanding not just the number 
          of restaurants and customers, but spatial patterns of the type of restaurants 
          and customers. Do you have the right restaurants in the right places for the 
          right customers?"
          image={slide3Image}
          imageAttribution={`Photo by <a href="https://unsplash.com/@brunus?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Bruno Martins</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>`}
        />
        <SidebarSlide
          slide={4}
          shrinked={slideShrinked}
          {...(currentSlide === 4 && {ref: currentCardRef})}
          title="Hungry customers don't stay happy customers"
          subtitle="One of the things that matters most to the customer is when 
          they get their food. So much of this is dependent on the routing 
          decisions used to connect restaurants to their customers."
          text="In London the average delivery time is 25.4 minutes. Improving on this 
          drives customer satisfaction, and is something businesses should be striving 
          to do - but how? "
          image={slide4Image}
          imageAttribution={`Photo by <a href="https://unsplash.com/@emsmith?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Emmy Smith</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>`}
        />
        <SidebarSlide
          slide={5}
          shrinked={slideShrinked}
          {...(currentSlide === 5 && {ref: currentCardRef})}
          title="What is the geography of my business?"
          subtitle="One of the biggest blockers driving spatial-based insights is 
          looking at the wrong geography. An example of this would be analyzing 
          order delivery times at a city level, like this."
          text="Imagine you're a takeaway delivery driver. Now imagine all the things 
          that could delay your order. Your local area could be extra busy. A restaurant 
          could complete it late. There could be extra traffic, or a road closure. 
          You could struggle to find your customer's address. But your colleague in the 
          next suburb could be experiencing none of this. That's why it's important to 
          work at a relevant geography."
          image={slide5Image}
          imageAttribution={`Photo by <a href="https://unsplash.com/es/@vonshnauzer?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Egor Myznik</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>`}
        />
        <SidebarSlide
          slide={6}
          shrinked={slideShrinked}
          {...(currentSlide === 6 && {ref: currentCardRef})}
          title="A fresh perspective"
          subtitle="If we aggregate and analyze data to a spatial index like H3, then we 
          can start to see a story. We can see areas of spatial outliers and patterns, 
          with red and orange points on the map have higher delivery time."
          text="This is the first step in a delivery company’s spatial strategy! 
          They can use this intelligence to begin to drive decision making, such as 
          where to engage more restaurants or delivery drivers."
          image={slide6Image}
          imageAttribution={`Photo by <a href="Photo by <a href="https://unsplash.com/@maplerockdesign?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Richard Bell</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>`}
        />
        <SidebarSlide
          slide={7}
          shrinked={slideShrinked}
          {...(currentSlide === 7 && {ref: currentCardRef})}
          title="What other KPIs should I consider?"
          subtitle="As well as actual delivery time, a major source of customer 
          frustration is a large difference between estimated and actual delivery time. 
          This can be caused by physical factors (such as traffic or restaurant 
          congestion) but also errors with routing engines or driver-matching 
          algorithms."
          text="These are just a few examples of how a support geography - 
          such as a “H3 surface” - can be used to better develop an understanding 
          of your business and ultimately drive more intelligent decision making."
          image={slide7Image}
          imageAttribution={`Photo by <a href="https://unsplash.com/@jamessutton_photography?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">James Sutton</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>`}
        />
        <SidebarSlide
          slide={8}
          shrinked={slideShrinked}
          {...(currentSlide === 8 && {ref: currentCardRef})}
          title="Why is a continuous grid the most appropriate geography for your business?"
          subtitle="Many organizations conceptualize the geography of their business using arbitrary 
          or administrative boundaries such as local authorities or postcode areas. 
          This can result in high levels of underperformance."
          text="This is particularly the case for businesses reliant on routing. 
          This map shows the difference in journey times between when deliveries are routed to their 
          closest restaurant and when they’re routed to the closest restaurant within their postcode district. 
          Pink, taller cells have higher journey time differences - you can see in many cases these differences 
          are significant."
          image={slide8Image}
          imageAttribution={`Photo by <a href="https://unsplash.com/@farber?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Jonathan Farber</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>`}
        />
        <SidebarSlide
          slide={9}
          shrinked={slideShrinked}
          {...(currentSlide === 9 && {ref: currentCardRef})}
          title="So now you know where your performance needs improving. What next?"
          subtitle="Now you know where the problems are, you need to understand what is driving those problems."
          image={slide9Image}
          imageAttribution={`Photo by <a href="https://unsplash.com/@babak20?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Babak</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>`}
        >
          <p>
          Bringing multiple data sources into one common geography - such as data from CARTO's 
          &gt;10,500 dataset-strong Data Observatory - allows for fast and scalable cross-analysis to enable you to do this:
          </p>
          <ul>
            <li>Potential staff market - number of / propensity to use bikes and motorcycles (see map, left)</li>
            <li>Road speed data</li>
            <li>Market propensity to enjoy different restaurants</li>
            <li>Nearby POIs - bars, markets</li>
          </ul>
          <br/>
          <p>
          What decisions could this drive?
          </p>
          <ul>
            <li>Targeted recruitment for specific modes</li>
            <li>Better routing intelligence</li>
            <li>Pinpointing the right restaurants for the right areas</li>
          </ul>
        </SidebarSlide>
        <SidebarSlide
          slide={10}
          shrinked={slideShrinked}
          {...(currentSlide === 10 && {ref: currentCardRef})}
          title="Data-driven decision making for more efficient deliveries"
          subtitle="Organizations making decisions based on this level of data and interactivity 
          will be able to continuously monitor and improve their performance."
          text="This is crucial in staying on top of such a dynamic market."
          image={slide10Image}
        />
      </div>

      <div className={classes.footer}>
        <Fade in={currentSlide === 1}>
          <div>
            <Hidden smDown>
              <Button
                data-position="left"
                classes={{root: classes.footerItem}}
                startIcon={<IconActionHome />}
                color="primary"
                onClick={reset}
              >
                Home
              </Button>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                data-position="left"
                classes={{root: classes.footerItem}}
                color="primary"
                onClick={reset}
              >
                <IconActionHome />
              </IconButton>
            </Hidden>
          </div>
        </Fade>
        <Fade in={currentSlide > 1}>
          <IconButton
            data-position="left"
            classes={{root: classes.footerItem}}
            aria-label="Previous"
            color="primary"
            onClick={prev}
          >
            <ArrowLeft />
          </IconButton>
        </Fade>
        <div className={[classes.dots, classes.footerItem].join(' ')} data-position="center">
          {[...new Array(slidesNumber - 1)].map((item, i) => (
            <div
              key={`dot-${i + 1}`}
              className={classes.dot}
              data-active={i + 1 === currentSlide}
            />
          ))}
        </div>
        <Fade in={currentSlide !== slidesNumber - 1}>
          <div>
            <Hidden smDown>
              <Button
                data-position="right"
                classes={{root: classes.footerItem}}
                variant="contained"
                color="primary"
                onClick={next}
                endIcon={<ArrowRightWhite />}
              >
                Next
              </Button>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                data-position="right"
                classes={{root: [classes.footerItem, classes.iconButtonContained].join(' ')}}
                color="primary"
                onClick={next}
              >
                <ArrowRightWhite />
              </IconButton>
            </Hidden>
          </div>
        </Fade>
      </div>

      <div className={classes.swipeUpRoot}>
        <IconButton
          onClick={() => {
            setMobileExpanded(true);
          }}
          size="small"
        >
          <IconExpand />
        </IconButton>
      </div>
    </Drawer>
  );
};

export default Sidebar;
