import React from 'react';
import {Button, Typography, makeStyles, Box, useMediaQuery} from '@material-ui/core';
import {ReactComponent as NextIcon} from '../../assets/icons/icon-navigation-arrow-forward.svg';
import CoverBase from './CoverBase';
import {useAppState} from '../../state';

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(13.5),
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(8),
      '@media (min-height: 760px)': {
        top: theme.spacing(15)
      }
    }
  },
  title: {
    margin: theme.spacing(1, 0, 2),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1.5, 0, 4)
    }
  },
  pretitle: {
    fontWeight: 500,
    '& strong': {
      fontWeight: 600
    }
  },
  text: {
    opacity: 0.6
  }
}));

const CoverHero = () => {
  const {next} = useAppState();
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <div className={classes.root}>
      {/* <Typography
        className={classes.pretitle}
        color="inherit"
        variant={isDesktop ? 'h5' : 'subtitle1'}
      >
        Spatial Data Science Conference 2022
      </Typography> */}
      <Box className={classes.title}>
        <Typography color="inherit" variant={isDesktop ? 'h3' : 'h4'}>
          Improving food Delivery Time estimations using Spatial Analytics
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography color="inherit" variant={isDesktop ? 'body1' : 'body2'}>
          Since 2019, Brits have increased their spending on takeaways by 42%. 
          Londoners are no exception, spending on average £781 per year each. 
          However, competition amongst restaurants in the capital is high and  
          food should arrive when promised. If it doesn't, the customer can 
          demand refunds and take their business elsewhere.         
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography color="inherit" variant={isDesktop ? 'body1' : 'body2'}>
          Understanding the spatial nature of these problems is crucial. 
          Poor delivery times could be affected by factors such as busy restaurants, congested streets or 
          low availability of delivery drivers, all of which are inherently spatial.         
          So what data will delivery businesses have to assess performance?        
        </Typography>
      </Box>
      <Button
        size={isDesktop ? 'large' : 'medium'}
        variant="contained"
        color="primary"
        onClick={next}
        endIcon={<NextIcon />}
      >
        Start
      </Button>
    </div>
  );
};

export default CoverHero;
