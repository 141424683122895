export default [
  /* 0 */ {
    mapID: '64c49419-c982-4ce6-931c-276be4c23eb4',
    parameters: {depthTest: false},
  },
  /* 1 */ {
    mapID: '64c49419-c982-4ce6-931c-276be4c23eb4',
    parameters: {depthTest: false},
    orbit: true
  },
  /* 2 */ {
    mapID: '391c29f6-9566-4190-9850-aba052ec1bd1 ',
    props: {weightsTextureSize: 256}
  },
  /* 3 */ {
    mapID: '9110d051-a721-4fc2-bc60-63d96207317f',
    orbit: true,
    props: {coverage: 0.7}
  },
  /* 4 */ {
    mapID: '41aa171e-89f0-4741-acfd-7a795a551461',
  },
  /* 5 */ {
    mapID: '2f0ec847-8d56-4fb7-a8fc-d15e91364201',
  },
  /* 6 */ {
    mapID: '0b6c93cc-377a-47aa-9769-b4b5fc1df87a',
    props: {coverage: 0.7}
  },
  /* 7 */ {
    mapID: '9e35ffa1-e291-4c48-b2a8-5dcdafe93d2a',
    props: {coverage: 0.7}
  }
];
